export default {
  ease: {
    out: 'cubic-bezier(0.23, 1, 0.32, 1)',
    in: 'cubic-bezier(0, 0, 0.2, 1)',
    inOut: 'cubic-bezier(0.55, 0, 0.1, 1)',
    fade: 'cubic-bezier(0, 0, 0.25, 1)'
  },

  browser: {
    legacy:
      typeof Promise === 'undefined' ||
      Promise.toString().indexOf('[native code]') === -1,
    reducedMotion: window.matchMedia('(prefers-reduced-motion)').matches,
    wide: () => window.matchMedia(`(min-width: ${960 / 16}em)`).matches // resize can change value
  }
};
