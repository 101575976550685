import '../scss/app.scss'; // Vite requires css import in app.js

import { $ } from './helpers/query-selector';
import initLazyLoad from './modules/lazyload';
import initNav from './modules/nav';
import initAnims from './legacy/modules/animation';
import initHomeHero from './legacy/modules/home-hero';

function init() {
  initLazyLoad();
  initNav();
  initAnims();

  // Legacy imports
  initHomeHero();

  if ($('.js-header-sticky'))
    import('./modules/header-sticky').then(m => m.default());

  if ($('.js-map')) import('./modules/map').then(m => m.default());

  if ($('.js-iframe')) import('./modules/iframe').then(m => m.default());

  if ($('.js-lottie')) import('./modules/lottie').then(m => m.default());
  if ($('.js-lottie-scroll'))
    import('./modules/lottie-scroll').then(m => m.default());

  if ($('.js-form')) import('./modules/form').then(m => m.default());

  if ($('.js-slideshow')) import('./modules/slideshow').then(m => m.default());

  if ($('.js-citation')) {
    import('./modules/citation').then(m => m.default());
  }

  import('./modules/cookie-settings-button').then(m => m.default());

  import('./modules/tracking').then(m => m.default());

  if ($('.js-textarea')) import('./modules/textarea').then(m => m.default());

  if ($('.js-magazine-videos'))
    import('./modules/magazine-videos').then(m => m.default());

  if ($('.js-magazine-teaser-carousel'))
    import('./modules/magazine-teaser-carousel').then(m => m.default());

  if ($('.js-magazine-rubrics'))
    import('./modules/magazine-rubrics').then(m => m.default());

  if ($('.js-anchor-scroll'))
    import('./modules/anchor-scroll').then(m => m.default());

  if ($('.js-magazine-list-search')) {
    import('./modules/magazine-list-search').then(m => m.default());
  }

  if ($('.js-more')) {
    import('./modules/more').then(m => m.default());
  }

  if ($('.js-downloads-slider')) {
    import('./modules/downloads-slider').then(m => m.default());
  }

  if ($('.js-sustainability')) {
    import('./modules/sustainability').then(m => m.default());
  }

  if ($('.js-story')) {
    import('./modules/story').then(m => m.default());
  }

  // Async legacy imports
  if ($('.js-video-legacy')) {
    import('./legacy/modules/video-legacy').then(m => m.default());
  }

  if ($('.js-subservices')) {
    import('./legacy/modules/subservices').then(m => m.default());
  }

  if ($('.js-social-links')) {
    import('./legacy/modules/social-links').then(m => m.default());
  }

  if ($('.js-report-intro-hl')) {
    import('./legacy/modules/report-intro').then(m => m.default());
  }

  if ($('.js-report-key-figure')) {
    import('./legacy/modules/report-key-figure').then(m => m.default());
  }

  if ($('.js-report-milestone')) {
    import('./legacy/modules/report-milestone').then(m => m.default());
  }

  if ($('.js-report-nav-link')) {
    import('./legacy/modules/report-nav-link').then(m => m.default());
  }

  if ($('.js-report-slider')) {
    import('./legacy/modules/report-slider').then(m => m.default());
  }

  if ($('.js-report-solutions')) {
    import('./legacy/modules/report-solutions').then(m => m.default());
  }

  if ($('.js-report-text-toggle')) {
    import('./legacy/modules/report-text-toggle').then(m => m.default());
  }
}

init();
