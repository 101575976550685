// Match scss vars
const viewport = {
  s: window.matchMedia(`(min-width: ${576 / 16}em)`),
  m: window.matchMedia(`(min-width: ${768 / 16}em)`),
  l: window.matchMedia(`(min-width: ${992 / 16}em)`),
  xl: window.matchMedia(`(min-width: ${1200 / 16}em)`),
  xxl: window.matchMedia(`(min-width: ${1500 / 16}em)`)
};

const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

const saveData =
  'connection' in navigator && navigator.connection.saveData === true;

const easings = {
  out: 'cubic-bezier(0.23, 1, 0.32, 1)',
  in: 'cubic-bezier(0, 0, 0.2, 1)',
  inOut: 'cubic-bezier(0.55, 0, 0.1, 1)',
  fade: 'cubic-bezier(0, 0, 0.25, 1)'
};

export { viewport, reducedMotion, saveData, easings };
